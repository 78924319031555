import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AtsComponent } from './core/components/ats/ats.component'
import { LandingComponent } from './core/components/landing/landing.component'
import { MasterPageComponent } from './core/components/master-page/master-page.component'
import { PagePrivacyComponent } from './core/components/page-privacy/page-privacy.component'
import { PageTermsComponent } from './core/components/page-terms/page-terms.component'

const routes: Routes = [
  {
    path: '',
    component: MasterPageComponent,
    children: [
      { path: '', component: LandingComponent },
      { path: 'applicant-tracking-system', component: AtsComponent },
      // { path: 'workshops', component: EventsComponent },
      { path: 'privacy-policy', component: PagePrivacyComponent },
      { path: 'privacy', component: PagePrivacyComponent },
      { path: 'terms-of-service', component: PageTermsComponent },
    ],
  },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      scrollOffset: [0, 0],
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
