<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h1 class="title">Terms of Service</h1>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Start Terms & Conditions -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="card shadow border-0 rounded">
          <div class="card-body">
            <h5 class="card-title">Terms</h5>
            <p class="text-muted">
              By accessing the website at https://agile-ats.com, you are agreeing to be bound by these
              terms of service, all applicable laws and regulations, and agree that you are responsible
              for compliance with any applicable local laws. If you do not agree with any of these terms,
              you are prohibited from using or accessing this site. The materials contained in this
              website are protected by applicable copyright and trademark law.
            </p>

            <h5 class="card-title">Use License</h5>
            <p class="text-muted">
              <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather> Permission is granted
              to temporarily download one copy of the materials (information or software) on Agile
              Onboarding, LLC's website for personal, non-commercial transitory viewing only. This is the
              grant of a license, not a transfer of title, and under this license you may not:
            </p>
            <ul class="text-muted">
              <li>modify or copy the materials;</li>
              <li>
                use the materials for any commercial purpose, or for any public display (commercial or
                non-commercial);
              </li>
              <li>
                attempt to decompile or reverse engineer any software contained on Agile Onboarding,
                LLC's website;
              </li>
              <li>remove any copyright or other proprietary notations from the materials; or</li>
              <li>
                transfer the materials to another person or "mirror" the materials on any other server.
              </li>
            </ul>
            <p class="text-muted">
              <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>This license shall
              automatically terminate if you violate any of these restrictions and may be terminated by
              Agile Onboarding LLC at any time. Upon terminating your viewing of these materials or upon
              the termination of this license, you must destroy any downloaded materials in your
              possession whether in electronic or printed format.
            </p>

            <h5 class="card-title">Disclaimer</h5>
            <ul class="list-unstyled text-muted">
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                The materials on Agile Onboarding LLC's website are provided on an 'as is' basis. Agile
                Onboarding, LLC makes no warranties, expressed or implied, and hereby disclaims and
                negates all other warranties including, without limitation, implied warranties or
                conditions of merchantability, fitness for a particular purpose, or non-infringement of
                intellectual property or other violation of rights.
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Further, Agile Onboarding LLC does not warrant or make any representations concerning the
                accuracy, likely results, or reliability of the use of the materials on its website or
                otherwise relating to such materials or on any sites linked to this site.
              </li>
            </ul>

            <h5 class="card-title">Limitations</h5>

            <p class="text-muted">
              In no event shall Agile Onboarding, LLC or its suppliers be liable for any damages
              (including, without limitation, damages for loss of data or profit, or due to business
              interruption) arising out of the use or inability to use the materials on Agile Onboarding,
              LLC's website, even if Agile Onboarding, LLC or a Agile Onboarding, LLC authorized
              representative has been notified orally or in writing of the possibility of such damage.
              Because some jurisdictions do not allow limitations on implied warranties, or limitations
              of liability for consequential or incidental damages, these limitations may not apply to
              you.
            </p>

            <h5 class="card-title">Accuracy of Materials</h5>

            <p class="text-muted">
              The materials appearing on Agile Onboarding, LLC's website could include technical,
              typographical, or photographic errors. Agile Onboarding, LLC does not warrant that any of
              the materials on its website are accurate, complete or current. Agile Onboarding, LLC may
              make changes to the materials contained on its website at any time without notice. However
              AgileATS LLC does not make any commitment to update the materials.
            </p>

            <h5 class="card-title">Links</h5>

            <p class="text-muted">
              Agile Onboarding, LLC. has not reviewed all of the sites linked to its website and is not
              responsible for the contents of any such linked site. The inclusion of any link does not
              imply endorsement by AgileATS LLC of the site. Use of any such linked website is at the
              user's own risk.
            </p>

            <h5 class="card-title">Modifications</h5>

            <p class="text-muted">
              Agile Onboarding, LLC may revise these terms of service for its website at any time without
              notice. By using this website you are agreeing to be bound by the then current version of
              these terms of service.
            </p>

            <h5 class="card-title">Governing Law</h5>

            <p class="text-muted">
              These terms and conditions are governed by and construed in accordance with the laws of
              Florida and you irrevocably submit to the exclusive jurisdiction of the courts in that
              State or location.
            </p>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Terms & Conditions -->

<!-- Back to top -->
<a class="btn btn-icon btn-primary back-to-top" id="back-to-top" (click)="scrollTo('home')">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
