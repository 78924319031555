import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule, NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap'; // prettier-ignore
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AtsComponent } from './core/components/ats/ats.component';
import { EventsComponent } from './core/components/events/events.component';
import { LandingComponent } from './core/components/landing/landing.component';
import { MasterPageComponent } from './core/components/master-page/master-page.component';
import { PagePrivacyComponent } from './core/components/page-privacy/page-privacy.component';
import { PageTermsComponent } from './core/components/page-terms/page-terms.component';
import { ScheduleListComponent } from './core/components/schedule-list/schedule-list.component';
import { FooterComponent } from './shared/footer/footer.component';
import { HeaderComponent } from './shared/header/header.component';
import { SharedModule } from './shared/shared.module';
import { FeatherModule } from 'angular-feather';
import { allIcons } from 'angular-feather/icons';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    MasterPageComponent,
    LandingComponent,
    EventsComponent,
    PagePrivacyComponent,
    PageTermsComponent,
    AtsComponent,
    ScheduleListComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    FeatherModule.pick(allIcons),
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    CarouselModule,
    RouterModule.forRoot([], {
      relativeLinkResolution: 'legacy',
      anchorScrolling: 'enabled',
    }),
    NgbDropdownModule,
    NgbModule,
    NgbNavModule,
    SharedModule,
  ],
  exports: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [HttpClientModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
