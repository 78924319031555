import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { interval } from 'rxjs';
import { map } from 'rxjs/operators';
import { Email, LandingService } from '../../services/landing.service';

@Component({
  selector: 'agile-index-event',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css'],
})
export class EventsComponent implements OnInit {
  navClass = 'nav-light';
  formHasErrors = false;
  showSuccess = false;
  loading = false;
  workshopForm: FormGroup;
  _days: number | undefined;
  _hours: number | undefined;
  _minutes: number | undefined;
  _seconds: number | undefined;
  private _diff: number | undefined;
  private _trialEndsAt = '2022-07-28';

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private landingService: LandingService,
    private viewportScroller: ViewportScroller
  ) {
    this.calculateTime();
  }

  scrollTo(elementId: string) {
    this.viewportScroller.scrollToAnchor(elementId);
  }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.workshopForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      company: ['', Validators.required],
      admission: ['', Validators.required],
      type: 'workshop',
    });
  }

  calculateTime() {
    interval(1000)
      .pipe(
        map(() => {
          this._diff =
            Date.parse(this._trialEndsAt) - Date.parse(new Date().toString());
        })
      )
      .subscribe(() => {
        this._days = this.getDays(this._diff);
        this._hours = this.getHours(this._diff);
        this._minutes = this.getMinutes(this._diff);
        this._seconds = this.getSeconds(this._diff);
      });
  }

  /**
   * Open modal for show the video
   * @param content content of modal
   */
  openWindowCustomClass(content) {
    this.modalService.open(content, {
      windowClass: 'dark-modal',
      size: 'lg',
      centered: true,
    });
  }

  getDays(t: any) {
    return Math.floor(t / (1000 * 60 * 60 * 24));
  }

  getHours(t: any) {
    return Math.floor((t / (1000 * 60 * 60)) % 24);
  }

  getMinutes(t: any) {
    return Math.floor((t / 1000 / 60) % 60);
  }

  getSeconds(t: any) {
    return Math.floor((t / 1000) % 60);
  }

  submitForm() {
    this.loading = true;
    if (this.workshopForm.invalid) {
      this.formHasErrors = true;
      this.loading = false;
      return;
    }

    const email: Email = this.workshopForm.value;
    this.landingService.sendEmail(email).subscribe(
      () => this.handleSuccess(),
      (err) => this.handleError(err)
    );
  }

  handleSuccess() {
    this.showSuccess = true;
    this.loading = false;
    this.workshopForm.reset();
    this.formHasErrors = false;
  }

  handleError(err: Error) {
    console.error(err);
    this.loading = false;
    this.formHasErrors = false;
  }

  get name() {
    return this.workshopForm.get('name');
  }

  get email() {
    return this.workshopForm.get('email');
  }

  get phone() {
    return this.workshopForm.get('phone');
  }

  get admission() {
    return this.workshopForm.get('admission');
  }

  get company() {
    return this.workshopForm.get('company');
  }
}
