import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

export interface Email {
  name: string;
  type: 'workshop' | 'demo' | 'contact';
  company: string;
  email: string;
  question?: string;
}

@Injectable({
  providedIn: 'root',
})
export class LandingService {
  constructor(private http: HttpClient) {}

  sendEmail(email: Email) {
    return this.http.post(environment.sendEmailLambdaEndpoint, email);
  }
}
