import { Component, Input } from '@angular/core';

@Component({
  selector: 'agile-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent {
  @Input() footerVariant: string;
  @Input() hideFooter: boolean;
  year = new Date().getFullYear();
}
