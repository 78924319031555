<!-- Hero Start -->
<section
  class="bg-home d-flex align-items-center"
  style="
    background: url('assets/images/saas/home-shape.png') center center;
    height: auto;
  "
  id="home"
>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center mt-0 mt-md-5 pt-0 pt-md-5">
        <div class="title-heading margin-top-100">
          <h1 class="display-4 fw-bold mb-3">AgileATS</h1>
          <p class="catch-line mx-auto text-muted">Build a Culture of Hiring</p>
          <div class="mt-4 pt-2 d-flex justify-content-center">
            <a
              href="https://agileats.pipedrive.com/scheduler/RpzXwBhy/agileats-demo"
              target="_blank"
              rel="noopener noreferrer"
              class="btn btn-primary demo-btn"
            >
              Request Demo <i class="mdi mdi-chevron-right"></i
            ></a>
            <a class="btn btn-secondary" (click)="openContactUsModal()"
              >Contact Us</a
            >
          </div>
          <!-- <div class="mt-4 pt-2">
            <a href="#about" class="btn btn-primary" (click)="openDemoFormModal()">
              Request Demo
              <i class="mdi mdi-chevron-right"></i
            ></a>
          </div>
          <div class="mt-2 pt-2">
            <a class="btn btn-secondary" (click)="openContactUsModal()">Contact Us</a>
          </div> -->
          <p id="slogan" class="para-desc mt-4 display-6 mx-auto text-muted">
            Your company’s hiring process should be so impressive that
            Candidates who experience it<br />
            <strong>want to work for you</strong>.
          </p>
        </div>

        <div class="home-dashboard">
          <!-- <img
            src="assets/images/ao-board-macbook.png"
            alt="Hiring software solutions"
            class="img-fluid"
            style="pointer-events: none; user-select: none"
          /> -->
          <iframe
            src="https://www.youtube.com/embed/bJxl0YVU0qc?controls=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- as seen -->
<section class="section-sm mt-md-5">
  <div class="container">
    <div class="as-seen-in-container">
      <div class="row no-margin">
        <div class="col-sm-12">
          <div class="as-seen-in-info">
            <div class="as-seen-in-element">
              <img
                class="as-seen-in-image"
                src="assets/images/forbes-logo.png"
                alt="as-seen-1"
              />
            </div>
            <div class="as-seen-in-element">
              <img
                class="as-seen-in-image"
                src="assets/images/new-york-times-logo.png"
                alt="as-seen-2"
              />
            </div>
            <div class="as-seen-in-element">
              <img
                class="as-seen-in-image"
                src="assets/images/wsj-logo.png"
                alt="as-seen-3"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Partners start -->
<section class="section bg-light mt-0 mt-md-3">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">
            See who’s hiring with <span class="text-primary">AgileATS</span>
          </h4>
          <p class="text-muted para-desc mx-auto mb-4">
            By utilizing the
            <span class="text-primary fw-bold">AgileATS</span> software,
            companies have significantly streamlined their applicant tracking
            and hiring processes.
          </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
    <agile-clients-logo></agile-clients-logo>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Partners End -->

<!-- Feature Start -->
<section class="section">
  <div class="container">
    <app-services [servicesData]="servicesData"></app-services>
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-6">
        <img
          src="assets/images/job-page.png"
          class="img-fluid shadow rounded"
          alt="Hiring software"
        />
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title ms-lg-5">
          <h4 class="title mb-4">Cast a wide net</h4>
          <p class="text-muted">
            We make publishing jobs easy with our Bulk Publish feature -
            instantly posting your jobs, to your custom-branded, Jobs Page -
            which we create for you.
          </p>
          <p class="text-muted">
            Jobs are then automatically published to 3rd party sites, such as
            Indeed.
          </p>
          <p class="text-muted">
            Applicants can easily apply on your company’s website and your
            hiring team can easily move through applications.
          </p>
          <p class="text-muted">
            <strong
              >Recruiting for hard to fill roles, with no applicants?</strong
            ><br />Bulk upload resumes and move through your candidates at
            lightning speed with our industry-leading Task Manager.
          </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-6">
        <div class="section-title">
          <h4 class="title mb-4">Make them feel your culture</h4>
          <p class="text-muted">
            AgileATS lets your hiring staff connect with more candidates, more
            quickly but still leaving candidates feeling unique and cared-for
            through the process.
          </p>
          <p class="text-muted">
            Create custom email templates which come from
            <strong><em>your company’s domain!</em></strong> Not from some
            generic, ” HYPERLINK "mailto:do-not-reply@crappyHRtool.com"
            do-not-reply@crappyHRtool.com”. (like most other tools).
          </p>
          <p class="text-muted">
            Set yourself apart from other companies by sending video messages
            with the click of a button.
          </p>
          <p class="text-muted">
            Send your candidates text messages instantly with the Agile Chat
            feature
          </p>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <img
          src="assets/images/email-template.png"
          class="img-fluid shadow rounded"
          alt="Employee onboarding software"
        />
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-6">
        <img
          src="assets/images/new-board-page.png"
          class="img-fluid shadow rounded"
          alt="HR onboarding software"
        />
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title ms-lg-5">
          <h4 class="title mb-4">Hiring, the Agile way</h4>
          <p class="text-muted">
            Ensure that your company has a
            <strong><em>consistent hiring process</em></strong> for every
            candidate who comes through your door, by setting your hiring
            stages.
          </p>

          <p class="text-muted">
            Easily move your candidates through the hiring stages with
            drag-and-drop workflows. It’s so easy, you can’t mess it up.
          </p>
          <p class="text-muted">
            Internal stakeholders receive alerts, reminders, and notifications
            along the way.
          </p>
          <p class="text-muted">
            Candidates receive fun, culture-packed and informative communication
            along the way. Leaving them in awe of your company’s hiring process.
          </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-6">
        <div class="section-title">
          <h4 class="title mb-4">
            Manage your Recruiting and HR team all in one place
          </h4>
          <p class="text-muted">
            Ensure your leadership team has insights to your hiring.
          </p>
          <p class="text-muted">
            Our Dashboard creates a competitive environment among your
            recruiters, by indicating weekly, monthly and annual leaders at a
            glance.
          </p>
          <p class="text-muted">
            Our Analytics page lets you dive deep into the intricacies of your
            hiring process. Quickly visualize what’s making your hiring process
            slower, and where candidates fall-off.
          </p>
          <p class="text-muted">
            Prove that your company is compliant with DoL and EEO guidelines by
            using our click-of-the-button compliance reporting.
          </p>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <img
          src="assets/images/dashboard-page.png"
          class="img-fluid shadow rounded"
          alt="Best onboarding software"
        />
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-6">
        <img
          src="assets/images/compliance-page.png"
          class="img-fluid shadow rounded"
          alt="New hire onboarding software"
        />
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title ms-lg-5">
          <h4 class="title mb-4">Compliance... Solved.</h4>
          <p class="text-muted">
            By simply using AgileATS as it was designed, your company stays
            compliant.
          </p>
          <span class="text-muted">
            We offer:<br />
            <ul>
              <li>
                Equal Opportunity Employer (EEO) compliance (Component 1 and 2!)
              </li>
              <li>Affirmative Action Plan (AAP) compliance</li>
              <li>Office of Federal Contract Compliance (OFCCP)</li>
            </ul>
          </span>
          <p class="text-muted">
            All of these reports are exportable, with the simple click of a
            button.
          </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">
            Our Users Love the
            <span class="text-primary">AgileATS</span> Software
          </h4>
          <p class="text-muted para-desc mx-auto mb-0">
            Our relentless efforts to perfect the hiring process don't go
            unnoticed. See what our everyday users have to say about our
            software.
          </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row justify-content-center">
      <div class="col-lg-12 mt-4">
        <agile-testimonial
          [testimonialData]="testimonialData"
        ></agile-testimonial>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container pricing">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title">
          <img
            src="assets/images/pricing.jpg"
            alt="pricing"
            class="img-fluid"
            style="pointer-events: none; user-select: none"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title mb-4">Ready to become great at hiring?</h4>
          <p class="text-muted para-desc mx-auto mb-0">
            With dashboards highlighting what's most important, task management
            workflow that keep things on track, and a streamlined candidate
            hiring workflow to make the hiring process organized and seamless
            for your company.
          </p>

          <div class="mt-4">
            <!-- <a href="javascript:void(0)" class="btn btn-primary mt-2 me-2"
              >Get Started Now</a
            > -->
            <a
              href="https://agileats.pipedrive.com/scheduler/RpzXwBhy/agileats-demo"
              target="_blank"
              rel="noopener noreferrer"
              class="btn btn-outline-primary mt-2"
              >Request Demo</a
            >
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mb-60">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title">
          <h4 class="title mb-4 text-center">
            Increase Productivity with our Hiring Software
          </h4>
          <p class="text-muted text-center para-desc-lg mx-auto">
            Make introducing new employees to your company stress-free with
            AgileATS. Our software reduces the time it takes to complete
            orientation activities for your new employees. We help you:
          </p>
          <div class="text-muted productivity-help-list">
            <ul>
              <li>Create clear expectations for new employees</li>
              <li>Provide new hires with the right tools</li>
              <li>Ensure employee goals align with your objectives</li>
            </ul>
          </div>
          <p class="text-muted text-center para-desc-lg mx-auto">
            The applicant tracking system makes it easy to monitor all HR tasks
            and formalities associated with the hiring experience. Using the
            best hiring software allows your new hires to start giving you their
            best from day one.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="container mb-60">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title">
          <h4 class="title mb-4 text-center">
            The Benefits of Cloud-Based Employee Hiring Software
          </h4>
          <p class="text-muted text-center para-desc-lg mx-auto">
            Cloud-based software provides you with increased freedom to handle
            all parts of the employee hiring process. You can access our
            employee hiring solution from any location and with any device. All
            members of your hiring team can review applicants and job
            descriptions, including hiring managers and HR representatives.
            Cloud processing provides you with the ability to update and save
            your job postings. Our cloud system provides you with:
          </p>
          <div class="text-muted productivity-help-list">
            <ul>
              <li>Around the clock support</li>
              <li>Customization options</li>
              <li>Easy set up for your hiring portal</li>
            </ul>
          </div>
          <p class="text-muted text-center para-desc-lg mx-auto">
            We focus on maintaining cost-effective cloud solutions designed to
            meet your needs for HR onboarding.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="container mb-60">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title">
          <h4 class="title mb-4 text-center">
            Avoid the Headaches of the Old Hiring Process
          </h4>
          <p class="text-muted text-center para-desc-lg mx-auto">
            Traditional recruitment and hiring processes frustrate employers and
            new hires. Our team set out to create a hiring solution to address
            problems caused by:
          </p>
          <div class="text-muted productivity-help-list">
            <ul>
              <li>Lost or missing paperwork</li>
              <li>Compliance issues</li>
              <li>Forgotten or missed tasks</li>
              <li>Issues with data entry and 1-9 processing</li>
              <li>Complications with storing credentials</li>
            </ul>
          </div>
          <p class="text-muted text-center para-desc-lg mx-auto">
            Many new hires expect an unimpressive experience on their first day.
            So, we streamline the entire hiring process, providing new hires
            with the information they want to boost engagement right away.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="container mb-60">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center para-desc-lg mx-auto">
          <h4 class="title mb-4 text-center">
            Scalable Applicant Tracking Functionality for Your Business
          </h4>
          <p class="text-muted">
            Our HR software scales with your business, helping you feel
            comfortable as your business grows and expands. We understand that
            the hiring process looks different for companies with ten employees
            and companies with 1,000.
          </p>
          <p class="text-muted">
            Companies with more employees often want increased options for
            employee self-service and automated workflows. Adjust the hiring
            tools that work best for your business as you grow and expand. We
            support our clients with customized workflows that they can adapt to
            meet the changing needs of their business.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="container mb-60">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title">
          <h4 class="title mb-4 text-center">Automate Your Hiring Tasks</h4>
          <p class="text-muted text-center para-desc-lg mx-auto">
            Legally, your company must complete several hiring tasks when
            bringing in new hires. Forgetting these steps makes it difficult to
            get your employees ready to work. We help you automate hiring tasks,
            ensuring they get completed for each person you hire.
          </p>
          <p class="text-muted text-center para-desc-lg mx-auto">
            Use our hiring system to set up checklists that move new employees
            throughout the entire process. You can set up operations that
            involve:
          </p>
          <div class="text-muted productivity-help-list">
            <ul>
              <li>Filing electronic forms (including I-9 and W-4forms)</li>
              <li>Completing a health check-up</li>
              <li>Handling a reference check</li>
              <li>Providing safety training</li>
              <li>Updating employee data</li>
            </ul>
          </div>
          <p class="text-muted text-center para-desc-lg mx-auto">
            We understand that the new hire hiring processes vary based on your
            company's needs. Select the HR processes you want to focus on when
            looking through our hiring software options.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="container mb-60">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title">
          <h4 class="title mb-4 text-center">
            Boost Employee Engagement Through the Hiring Process
          </h4>
          <p class="text-muted text-center para-desc-lg mx-auto">
            Employee engagement occurs when your new hires feel a connection to
            your company. Engaged employees constantly strive to do their best,
            supporting the goals of your business. Using the best employee
            hiring softwares make it easier for you to cultivate employee
            engagement. Employees feel more engaged when they understand:
          </p>
          <div class="text-muted productivity-help-list">
            <ul>
              <li>The goals of your company</li>
              <li>Your expectations</li>
              <li>Their personal responsibilities</li>
            </ul>
          </div>
          <p class="text-muted text-center para-desc-lg mx-auto">
            We make it easy to transmit this information as part of the training
            process, improving the employee experience. Your employees quickly
            access the employee handbook through our training platform. In
            addition, they can review the document at their leisure, making it
            easy to onboard new hires. Our cloud-based system also allows you to
            make adjustments to the handbook easily.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="container mb-60">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title">
          <h4 class="title mb-4 text-center">
            Benefits of an Employee Service System
          </h4>
          <p class="text-muted text-center para-desc-lg mx-auto">
            Consider setting up a self-service portal for your employees. These
            systems make it easy for new hires to access an employee portal. The
            portal can provide access to many tools that allow employees to
            handle their work. Set up a place for team members to take care of
            benefits management, including paperwork for:
          </p>
          <div class="text-muted productivity-help-list">
            <ul>
              <li>Health insurance</li>
              <li>Dental insurance</li>
              <li>Short and long-term disability insurance</li>
            </ul>
          </div>
          <p class="text-muted text-center para-desc-lg mx-auto">
            The HR system also allows you to handle internal processes like
            employee feedback throughout the entire employee lifecycle. The
            personal portal also allows new hires to get information about
            payroll processing. Give your employees a chance to look at past pay
            stubs and their current hours.
          </p>
          <p class="text-muted text-center para-desc-lg mx-auto"></p>
        </div>
      </div>
    </div>
  </div>

  <div class="container mb-60">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title">
          <h4 class="title mb-4 text-center">
            Stay Connected with All Your Departments
          </h4>
          <p class="text-muted text-center para-desc-lg mx-auto">
            Applicant Tracking platforms make it easy to handle new hires and
            core HR capabilities. Our system also allows you to set up automated
            notifications to departments throughout your company as part of the
            hiring workflow. Set up automated task notifications with our
            applicant tracking tool to keep you in contact with team members in
            the following:
          </p>
          <div class="text-muted productivity-help-list">
            <ul>
              <li>Administration department</li>
              <li>HR department</li>
              <li>Payroll department</li>
              <li>IT and security department</li>
            </ul>
          </div>
          <p class="text-muted text-center para-desc-lg mx-auto">
            Use our software to send a message to IT each time you hire a new
            employee who needs access to your network. Handle applicant tracking
            by ensuring automatic notifications go out to payroll with each
            personnel change you make.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="container mb-60">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title">
          <h4 class="title mb-4 text-center">
            Use Our Software for Performance Management
          </h4>
          <p class="text-muted text-center para-desc-lg mx-auto">
            Our HR software makes it easy for you to assess the efforts of your
            new hires. Performance management helps you track milestones, like
            the completion of training programs. Set up tools to automatically
            update employee records as new hires finish hiring tasks.
          </p>
          <p class="text-muted text-center para-desc-lg mx-auto">
            Software vendors often make special hiring software offers. Our team
            focuses on developing the top hiring software to handle all your
            needs and make the hiring process as simple as possible. We focus on
            providing services that assist with reviewing applications,
            selecting the right individual for the job, and even handling
            benefits administration.
          </p>
          <p class="text-muted text-center para-desc-lg mx-auto">
            Our new hiring software provides you with a consistent solution to
            your hiring needs. In addition, we strive to make hiring the same
            for each employee you hire. Consistency takes the stress out of
            hiring for existing and potential team members. Please find out more
            about what we offer by requesting a demo of our software. We look
            forward to working with you.
          </p>
          <div class="d-flex justify-content-center mt-4">
            <a
              href="https://agileats.pipedrive.com/scheduler/RpzXwBhy/agileats-demo"
              target="_blank"
              rel="noopener noreferrer"
              class="btn btn-outline-primary mt-2"
            >
              Request Demo
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--end sectipn-->
<div class="position-relative">
  <div class="shape overflow-hidden text-footer">
    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Client End -->

<!-- Back to top -->
<a
  href="javascript: void(0);"
  class="btn btn-icon btn-primary back-to-top"
  id="back-to-top"
  (click)="scrollTo('home')"
>
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
