import { Component } from '@angular/core';

@Component({
  selector: 'agile-ats',
  templateUrl: './ats.component.html',
  styleUrls: ['./ats.component.css'],
})
export class AtsComponent {
  constructor() {}
}
