import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Email, LandingService } from '../../core/services/landing.service';

@Component({
  selector: 'agile-contact-us-form',
  templateUrl: './contact-us-modal.component.html',
  styleUrls: ['./contact-us-modal.component.css'],
})
export class ContactUsModalComponent implements OnInit {
  contactForm: FormGroup;
  showSuccess = false;
  formHasErrors = false;
  loading = false;
  constructor(
    private formBuilder: FormBuilder,
    private landingService: LandingService
  ) {}

  ngOnInit() {
    this.buildContactForm();
  }

  buildContactForm() {
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      company: [''],
      question: '',
      type: 'contact',
    });
  }

  submitForm() {
    this.loading = true;
    if (this.contactForm.invalid) {
      this.formHasErrors = true;
      this.loading = false;
      return;
    }

    const email: Email = this.contactForm.value;

    this.landingService.sendEmail(email).subscribe(
      () => this.handleSuccess(),
      (err) => this.handleError(err)
    );
  }

  handleSuccess() {
    this.showSuccess = true;
    this.loading = false;
    this.formHasErrors = false;
    this.contactForm.reset();
  }

  handleError(err: Error) {
    console.error(err);
    this.loading = false;
    this.formHasErrors = false;
  }

  get name() {
    return this.contactForm.get('name');
  }

  get question() {
    return this.contactForm.get('name');
  }

  get email() {
    return this.contactForm.get('email');
  }

  get phone() {
    return this.contactForm.get('phone');
  }

  get company() {
    return this.contactForm.get('company');
  }
}
