import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ClientsLogoComponent } from './clients-logo/clients-logo.component';
import { ContactUsModalComponent } from './contact-us-modal/contact-us-modal.component';
import { TestimonialComponent } from './testimonial/testimonial.component';
import { FeatherModule } from 'angular-feather';
import { allIcons } from 'angular-feather/icons';

@NgModule({
  declarations: [
    TestimonialComponent,
    ClientsLogoComponent,
    ContactUsModalComponent,
  ],
  imports: [
    CommonModule,
    CarouselModule,
    RouterModule,
    FormsModule,
    FeatherModule.pick(allIcons),
    HttpClientModule,
    ReactiveFormsModule,
  ],
  exports: [
    TestimonialComponent,
    ClientsLogoComponent,
    ContactUsModalComponent,
  ],
})
export class SharedModule {}
