import { ViewportScroller } from '@angular/common';
import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactUsModalComponent } from '../../../shared/contact-us-modal/contact-us-modal.component';

@Component({
  selector: 'agile-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css'],
})
export class LandingComponent {
  constructor(
    private modalService: NgbModal,
    private viewportScroller: ViewportScroller
  ) {}

  testimonialData = [
    {
      name: 'Erin Jones',
      designation: 'Executive VP, HR and Operations',
      company: 'The Swift Group',
      message: `"Our team has been using AgileATS for nearly 3 years and we love it. The tool helps us hire more quickly, but also stay compliant by tracking all applications, interview history and rejection reasons. My team can easily run reports on our employees, their history and their compensation to help us make decisions.  Our set hiring stages also ensures that every candidate and employee experiences a consistent and equal hiring process.  With AgileATS, we are able to ensure that every candidate has a fun, yet precise hiring process."`,
    },
    {
      name: 'Mark Wasilko',
      designation: 'Director of Talent Acquisition',
      company: 'iC-1 Solutions',
      message: `"I run a team of 7 recruiters, with dozens of contracts and multiple openings.  AgileATS keeps our entire team on the same page, so no actions are forgotten.  In our industry, when candidates come on the market, they go fast.  AgileATS ensures that we driving the hiring process at lightening-fast speed, keeping all parties informed.  My entire team loves the tool, and we'd never use another ATS."`,
    },
    {
      name: 'Dean McKendrick',
      designation: 'Executive VP',
      company: 'Counter Threat Solutions',
      message: `"AgileATS has provided our team with an easy to navigate applicant tracking system with intuitive configuration, consistent business rules, dashboard visualization, tailored analytics and metrics, and team communications. Our productivity gains and ability to move candidates efficiently through our hiring process is a game changer."`,
    },
    {
      name: 'Tyler Crockett',
      designation: 'Founder / President',
      company: 'Crimson Phoenix Government Solutions',
      message: `"After using Bullhorn for nearly 3 years, we decided to switch to AgileATS, and couldn't be happier.  It's been a game changer for our recruiting team. I have used a half-dozen ATS's and AgileATS is hands-down the best on the market."`,
    },
    {
      name: 'Krystal Bier',
      designation: 'Recruiter',
      company: 'iC-1 Solutions',
      message: `"I love how easily it is to add candidates, and move through my call list.  The system allows me to call, email and text very quickly, logging all notes for other team members to see. It also helps me keep my Tasks organized so I don't miss any good candidates.  I also love how our entire team can see where each candidate is in the hiring process, and make sure that things move quickly - it really ensures that our candidates get an impressive hiring and onboarding process."`,
    },
    {
      name: 'Adam S. Rand',
      designation: 'COO',
      company: 'Sequoia',
      message: `"AGILE has definitely helped us keep things on track and we are loving it."`,
    },
  ];

  servicesData = [
    {
      icon: 'uil uil-draggabledots h1 text-primary',
      title: 'Drag and Drop Collaboration',
      description:
        "Get a bird's-eye view of your entire hiring pipeline with our collaborative hiring board. All recruiters and managers can easily see your hiring workflows, and quickly remove blockers and obstacles in the hiring process.",
    },
    {
      icon: 'uil uil-analytics h1 text-primary',
      title: 'Agile Analytics',
      description:
        'Our Agile Analytics features enable administrators and managers to analyze key hiring statistics, manage employee performance, generate reports, and more.',
    },
    {
      icon: 'uil uil-create-dashboard h1 text-primary',
      title: 'Compliance, Solved',
      description:
        'Our application gives you one-click compliance reports for EEO, AAP and OFCCP.',
    },
    {
      icon: 'uil uil-hipchat h1 text-primary',
      title: 'Agile Chat',
      description:
        'Seamlessly send text messages to external candidates and prospects for faster results.',
    },
    {
      icon: 'uil uil-process h1 text-primary',
      title: 'Consistent Hiring Process',
      description:
        "Our innovative Hiring Board enables recruiters and managers to consistently take candidates through pre-configured, custom hiring 'stages', which are consistent, automated and fun.",
    },
    {
      icon: 'uil uil-suitcase h1 text-primary',
      title: 'AgileATS Jobs',
      description:
        "Our platform provides a solution to publish your company's open jobs to a custom, branded website with your own domain where candidates can easily apply. ",
    },
  ];

  openContactUsModal() {
    this.modalService.open(ContactUsModalComponent, {
      size: 'md',
      centered: true,
    });
  }

  scrollTo(elementId: string) {
    this.viewportScroller.scrollToAnchor(elementId);
  }
}
