<agile-header
  [navClass]="addclass"
  [buttonList]="buttonShow"
  [sliderTopbar]="TopbarShow"
  [isdeveloper]="developerPage"
  [shopPages]="shopPages"
></agile-header>

<router-outlet (activate)="onActivate($event)"></router-outlet>

<agile-footer
  [footerVariant]="footerClass"
  [hideFooter]="hideFooter"
></agile-footer>
