<div class="col" id="forms">
  <div class="component-wrapper rounded shadow">
    <div class="p-4 border-bottom">
      <h4 class="title mb-0">Contact Us</h4>
    </div>

    <div class="p-4">
      <form
        [formGroup]="contactForm"
        [ngClass]="showSuccess ? 'hidden' : 'fadeInUp animatedFadeInUp animated'"
      >
        <div class="row">
          <div class="col-md-12">
            <div class="mb-3">
              <label class="form-label">Full Name <span class="text-danger">*</span></label>
              <div class="form-icon position-relative">
                <i-feather name="user" class="fea icon-sm icons"></i-feather>
                <input
                  name="name"
                  id="name"
                  type="text"
                  class="form-control ps-5"
                  formControlName="name"
                  placeholder="First Name"
                />
                <small class="text-danger" *ngIf="formHasErrors && name.invalid"
                  >Your full name is required! Please enter your full name and try again!</small
                >
              </div>
            </div>
          </div>
          <!--end col-->
          <div class="col-md-12">
            <div class="mb-3">
              <label class="form-label">Work Email <span class="text-danger">*</span></label>
              <div class="form-icon position-relative">
                <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                <input
                  name="email"
                  id="email"
                  type="email"
                  class="form-control ps-5"
                  placeholder="Work Email"
                  formControlName="email"
                />
                <small class="text-danger" *ngIf="formHasErrors && email.invalid"
                  >Your work email is required! Please enter your work email and try again!</small
                >
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="mb-3">
              <label class="form-label">Work Phone <span class="text-danger">*</span></label>
              <div class="form-icon position-relative">
                <i-feather name="phone" class="fea icon-sm icons"></i-feather>
                <input
                  name="phone"
                  id="phone"
                  type="phone"
                  class="form-control ps-5"
                  placeholder="Work Phone"
                  formControlName="phone"
                />
                <small class="text-danger" *ngIf="formHasErrors && phone.invalid"
                  >Your work phone is required! Please enter your work phone and try again!</small
                >
              </div>
            </div>
          </div>

          <!--end col-->
          <div class="col-md-12">
            <div class="mb-3">
              <label class="form-label">Company</label>
              <div class="form-icon position-relative">
                <i-feather name="globe" class="fea icon-sm icons"></i-feather>
                <input
                  name="company_name"
                  id="company_name"
                  class="form-control ps-5"
                  placeholder="Company Name"
                  formControlName="company"
                />
                <small class="text-danger" *ngIf="formHasErrors && company.invalid">
                  Your company's name is required! Please enter your company's name and try again!
                </small>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="mb-3">
              <label class="form-label">Questions<span class="text-danger">*</span></label>
              <div class="form-icon position-relative">
                <i-feather name="message-square" class="fea icon-sm icons"></i-feather>
                <textarea
                  id="question"
                  rows="3"
                  name="question"
                  type="textarea"
                  class="form-control ps-5"
                  placeholder="Write your question here..."
                  formControlName="question"
                ></textarea>
                <small class="text-danger" *ngIf="formHasErrors && question.invalid">
                  Your work question is required! Please enter your question and try again!
                </small>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
        <div class="row">
          <div class="col-sm-12 justify-content-end">
            <button
              type="button"
              id="submit"
              name="send"
              class="btn btn-primary float-right"
              [ngClass]="{ spinner: loading }"
              (click)="submitForm()"
            >
              Send Message
            </button>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </form>

      <div
        class="row"
        *ngIf="showSuccess"
        [ngClass]="showSuccess ? 'fadeInUp animatedFadeInUp animated' : 'hidden'"
      >
        <div class="col-md-12 success-message">
          <div class="text-align-center-center p-4">
            <div class="d-flex justify-content-end">
              <button
                class="btn btn-icon btn-pills btn-outline-success"
                type="button"
                (click)="showSuccess = false"
              >
                <i-feather name="x" class="fea icon-sm icons"></i-feather>
              </button>
            </div>
            <h3>Request Sent!</h3>
            <p>Thank for you for reaching out!</p>
            <strong>Next Steps:</strong>
            <p>Our CEO, Ross O'Rourke, will personally reach out to you.</p>
            <p>We are excited to talk to you about our platform!</p>
          </div>
        </div>
      </div>

      <!--end form-->
    </div>
  </div>
</div>
