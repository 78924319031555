import { Component, OnInit } from "@angular/core";

interface client {
  image: string;
  class: string;
}

@Component({
  selector: "agile-clients-logo",
  templateUrl: "./clients-logo.component.html",
  styleUrls: ["./clients-logo.component.css"],
})
export class ClientsLogoComponent implements OnInit {
  clientLogos: client[] = [
    {
      image: "assets/images/client/cts-logo.png",
      class: "avatar avatar-ex-sm",
    },
    {
      image: "assets/images/client/ic-1-logo.png",
      class: "avatar avatar-ex-sm",
    },
    {
      image: "assets/images/crimson-phoenix.png",
      class: "avatar avatar-md-sm crimson-phoenix",
    },
    {
      image: "assets/images/client/swift-group-logo.png",
      class: "avatar avatar-ex-sm",
    },
    {
      image: "assets/images/client/darkStar-logo.png",
      class: "avatar avatar-md-sm crimson-phoenix",
    },
    {
      image: "assets/images/client/war-collar-logo.png",
      class: "avatar avatar-md-sm",
    },
    {
      image: "assets/images/client/tlg-worldwide-logo.png",
      class: "avatar avatar-md-sm crimson-phoenix",
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
