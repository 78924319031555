<div class="container d-flex align-items-center justify-content-around">
  <!-- Days -->
  <div class="row">
    <div class="col-lg-6 mb-3" id="Friday, Nov 13th">
      <h4 class="mt-0 mb-5 text-dark op-8 font-weight-bold text-center text-primary">
        Tuesday, July 26th
      </h4>
      <ul class="list-timeline list-timeline-primary">
        <li class="list-timeline-item p-0 pb-3 pb-lg-4 d-flex flex-wrap flex-column">
          <p class="my-0 text-dark flex-fw text-sm text-uppercase">
            <strong class="text-inverse op-8">9:00 AM</strong> - Workshop Start
          </p>
        </li>
        <li
          class="list-timeline-item p-0 pb-3 pb-lg-4 d-flex flex-wrap flex-column"
          data-toggle="collapse"
          data-target="#day-1-item-2"
        >
          <p class="my-0 flex-fw text-sm text-uppercase">
            <strong class="text-inverse op-8">9:15 - 12:00 PM</strong> - Discuss what an Agile Recruiting
            team is, and how to create one at your company.
          </p>
          <p class="my-0 text-dark flex-fw text-sm"></p>
        </li>
        <li class="list-timeline-item p-0 pb-3 pb-lg-4 d-flex flex-wrap flex-column">
          <p class="my-0 text-dark flex-fw text-sm text-uppercase">
            <strong class="text-inverse op-8">12:00 - 1:00 PM</strong> - Lunch Break
          </p>
        </li>
        <li
          class="list-timeline-item p-0 pb-3 pb-lg-4 d-flex flex-wrap flex-column"
          data-toggle="collapse"
          data-target="#day-1-item-4"
        >
          <p class="my-0 text-dark flex-fw text-sm text-uppercase">
            <strong class="text-inverse op-8">1:00 - 5:00 PM</strong> - Agile Recruiting, Processes and
            Tactics
          </p>
        </li>
        <li class="list-timeline-item p-0 pb-3 d-flex flex-wrap flex-column">
          <p class="my-0 text-dark flex-fw text-sm text-uppercase">
            <strong class="text-inverse op-8">5:00 PM</strong> - Conclude Day One
          </p>
        </li>
      </ul>
    </div>
    <div class="col-lg-6 mb-3" id="Saturday, Nov 14th">
      <h4 class="mt-0 mb-5 text-dark op-8 font-weight-bold text-center text-primary">
        Wednesday, July 27th
      </h4>
      <ul class="list-timeline list-timeline-primary">
        <li class="list-timeline-item p-0 pb-3 pb-lg-4 d-flex flex-wrap flex-column">
          <p class="my-0 text-dark flex-fw text-sm text-uppercase">
            <strong class="text-inverse op-8">9:00 AM</strong> - Start Day Two
          </p>
        </li>
        <li
          class="list-timeline-item p-0 pb-3 pb-lg-4 d-flex flex-wrap flex-column"
          data-toggle="collapse"
          data-target="#day-2-item-2"
        >
          <p class="my-0 text-dark flex-fw text-sm text-uppercase">
            <strong class="text-inverse op-8">9:15 - 12:00 PM</strong> - Deep dive into tactics, role
            plays and practice.
          </p>
        </li>
        <li class="list-timeline-item p-0 pb-3 pb-lg-4 d-flex flex-wrap flex-column">
          <p class="my-0 text-dark flex-fw text-sm text-uppercase">
            <strong class="text-inverse op-8">12:00 - 1:00 PM</strong> - Lunch Break
          </p>
        </li>
        <li
          class="list-timeline-item p-0 pb-3 pb-lg-4 d-flex flex-wrap flex-column"
          data-toggle="collapse"
          data-target="#day-2-item-4"
        >
          <p class="my-0 text-dark flex-fw text-sm text-uppercase">
            <strong class="text-inverse op-8">1:00 - 5:00 PM</strong> - Deep dive into tactics, role
            plays and practice.
          </p>
        </li>
        <li class="list-timeline-item p-0 pb-3 d-flex flex-wrap flex-column">
          <p class="my-0 text-dark flex-fw text-sm text-uppercase">
            <strong class="text-inverse op-8">5:00 PM</strong> - Conclude Workshop
          </p>
        </li>
      </ul>
    </div>
  </div>
</div>
