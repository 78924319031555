import { ViewportScroller } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-page-terms',
  templateUrl: './page-terms.component.html',
  styleUrls: ['./page-terms.component.css'],
})
export class PageTermsComponent {
  constructor(private viewportScroller: ViewportScroller) {}

  scrollTo(elementId: string) {
    this.viewportScroller.scrollToAnchor(elementId);
  }
}
