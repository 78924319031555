<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h1 class="title">Privacy Policy</h1>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Start Privacy -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="card shadow rounded border-0">
          <div class="card-body">
            <h5 class="card-title">Overview :</h5>
            <p class="text-muted">
              This Privacy Policy ("Policy") describes how AgileATS LLC and its subsidiaries and
              affiliates (collectively, "AgileATS," "we," "us," or "our") collect, use, store, and share
              your information.
            </p>
            <p class="text-muted">
              This Policy applies to information we collect from or about you: (1) when you access or use
              our mobile applications ("Applications"), websites ("Websites"), or products and/or
              services ("Products"); (2) when you communicate with us in any manner, including by email,
              telephone, and/or in person; and (3) from third party sources.
            </p>

            <h5>Acknowledgement</h5>
            <p class="text-muted">
              Please read this Policy carefully. The practices described herein are subject to the laws
              in the places in which we operate.
            </p>
            <p class="text-muted">
              <strong class="text-muted"
                >BY ACCESSING AND/OR USING OUR APPLICATIONS, WEBSITES, AND/OR PRODUCTS, OR BY
                COMMUNICATING WITH US IN ANY MANNER, YOU REPRESENT THAT YOU HAVE READ AND UNDERSTOOD THIS
                POLICY.</strong
              >
            </p>

            <h5 class="card-title">What Type Of Information Do We Collect About You?</h5>

            <p class="text-muted">
              Depending upon how you interact with our Websites, Applications, or Products, how you
              communicate with us, or our interactions with third parties, we may collect the following
              categories of information from and about you:
            </p>

            <ul class="list-unstyled text-muted">
              <li class="mb-3">
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                <strong>Direct identifiers</strong> , including but not limited to your unique online
                identifier, name, alias, social media handle, IP address, username and password, postal
                and/or e-mail address, account name and/or account number, social security number,
                driver's license number, passport number, and/or state identification number.
              </li>
              <li class="mb-3">
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                <strong>Related identifiers</strong>, including but not limited to your date of birth,
                bank, credit, or debit card number, financial information, insurance policy number,
                and/or license plate information.
              </li>
              <li class="mb-3">
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                <strong>Physical characteristics</strong>, including protected characteristics under
                federal and state law, such as age, sex, race, and ethnicity.
              </li>
              <li class="mb-3">
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                <strong>Commercial informations</strong>, including records of products or services
                purchased, obtained, or considered, and other purchasing and consuming histories and
                tendencies.
              </li>
              <li class="mb-3">
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                <strong>Biometric information</strong>, such as fingerprints.
              </li>
              <li class="mb-3">
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                <strong>Internet or other electronic network activity information</strong>, with our
                Websites, Applications, and communications, and including browser type and version,
                browser settings, operating systems and platform, device type, operating information,
                mobile carrier, and page response time and download errors.
              </li>
              <li class="mb-3">
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                <strong>Geolocation data</strong>, such as your zip code and time zone.
              </li>
              <li class="mb-3">
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                <strong>Audio, electronic, visual, and thermal information</strong>, including telephone
                recordings, electronic communication records, or other collected information.
              </li>
              <li class="mb-3">
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                <strong>Professional or employment-related information</strong>, such as your work
                history, salary history, and education history.
              </li>
            </ul>

            <h5 class="card-title">How Do We Collect Your Information?</h5>
            <p class="text-muted">We collect your information in three ways:</p>
            <p class="text-muted">
              Directly from you when you access or use our Applications, Websites, or Products, or when
              you communicate with us in any manner, including but not limited to:
            </p>
            <p class="text-muted">
              Information you provide by filling forms in person or electronically through our
              Applications or Websites, including information provided at the time of registering for any
              Product or event, posting material, or requesting further services or information;
            </p>
            <p class="text-muted">Information you provide when you access or use our Products;</p>
            <p class="text-muted">
              Information you provide when you report a problem with our Applications or Websites;
            </p>
            <p class="text-muted">
              Records and copies of your correspondence (e.g., email address) if you contact us;
            </p>
            <p class="text-muted">Your responses to surveys that we might ask you to complete;</p>
            <p class="text-muted">
              Details of transactions you carry out through our Applications, Websites, and the
              fulfillment of your orders; and
            </p>
            <p class="text-muted">Your search queries on the Websites.</p>
            <p class="text-muted">
              · Automatically when you access or use our Applications, Websites, or Products, or when you
              communicate with us, including but not limited to:
            </p>
            <p class="text-muted">
              Details of your visits to the Websites and Applications, including traffic data, location
              data, logs, and other communication data;
            </p>
            <p class="text-muted">
              Information about your computer and mobile device, and internet connection, including your
              IP address, operating system, and browser type; and
            </p>
            <p class="text-muted">
              Interactivity with an e-mail, including opening, navigating, and click-through information.
            </p>
            <p class="text-muted">· From third parties, including but not limited to:</p>
            <p class="text-muted">
              Data analytics providers for the purpose of receiving statistical data about your activity
              on our Websites and Applications;
            </p>
            <p class="text-muted">
              Social media networks for the purpose of collecting certain of your social media profile
              information and activity, including your screen names, profile picture, contact
              information, contact list, and the profile pictures of your contacts, through the social
              media platform;
            </p>
            <p class="text-muted">
              Third Party Service Providers, including but not limited to internet service providers,
              fraud prevention services, and related partners; and
            </p>
            <p class="text-muted">
              Public sources, including local, state, and federal government agencies and departments, to
              facilitate your use of the Websites and Applications, and to provide you with our Products.
            </p>
            <h5 class="card-title">How Do We Use Your Information?</h5>
            <p class="text-muted">
              We use the information outlined in What Type of Information Do We Collect About You for a
              variety of business and commercial purposes, including but not limited to:
            </p>
            <p class="text-muted">Provide the Applications and Websites to you;</p>
            <p class="text-muted">Provide the Products you have requested;</p>
            <p class="text-muted">
              Operate the Applications and Websites, including access management, payment processing,
              Application or Website administration, internal operations, troubleshooting, data analysis,
              testing, research, and for statistical purposes;
            </p>
            <p class="text-muted">Respond to your requests, feedback, or inquiries;</p>
            <p class="text-muted">Comply with laws, regulations, and other legal requirements;</p>
            <p class="text-muted">Comply with relevant industry standards and our policies;</p>
            <p class="text-muted">
              Protect and enforce your rights and the rights of other users against unlawful
            </p>
            <p class="text-muted">activity, including identity theft and fraud;</p>
            <p class="text-muted">
              Protect and enforce our collective rights arising under any agreements entered between
              Agile Onboarding and you or any other third party;
            </p>
            <p class="text-muted">Fulfill a transaction you requested or service your policy;</p>
            <p class="text-muted">Handle a claim;</p>
            <p class="text-muted">Create and manage your account;</p>
            <p class="text-muted">
              Protect the integrity and maintain security of our Applications, Websites, and Products;
            </p>
            <p class="text-muted">Operate, evaluate, and improve our business; and</p>
            <p class="text-muted">
              Provide you with information and advertisements about products, services, and promotions,
              from us or affiliates, that may interest you.
            </p>
            <p class="text-muted">
              Deliver content tailored to your interests and the manner in which you use the Applications
              and/or Websites;
            </p>
            <p class="text-muted">Present content in a manner that is optimized for your device;</p>
            <p class="text-muted">
              Measure and analyze the effectiveness of the Applications, Websites, and Products we
              provide to you.
            </p>
            <h5 class="card-title">How Do We Share Your Information?</h5>
            <p class="text-muted">
              We may, however, disclose or share information referenced in What Type of Information Do We
              Collect About You with others for a variety of business and commercial purposes, including:
            </p>
            <p class="text-muted">
              With your consent. We may disclose or share your information with your consent. We may
              obtain your consent in writing; online, through "click-through" agreements; when you accept
              the terms of use on our Applications or Websites; orally, either in person or on the phone;
              or by other means. You may consent to the transfer of your personal information by Agile
              Onboarding through such click-through agreements.
            </p>
            <p class="text-muted">
              In a business transfer. We may disclose or share your information as part of a corporate
              business transaction, such as a merger or acquisition, joint venture, corporate
              reorganization, financing, or sale of company assets, or in the unlikely event of
              insolvency, bankruptcy, or receivership, in which such information could be transferred to
              third parties as a business asset in the transaction.
            </p>
            <p class="text-muted">
              To non-affiliated third parties. We may disclose or share your information with certain
              third parties to facilitate your access and use of our Websites, Applications, and
              Products, including but not limited to internet service providers, advertising networks,
              data analytics providers, governmental entities, operating systems and platforms, social
              medial networks, and service providers who provide us a service (e.g., credit / debit card
              processing, billing, shipping, repair, customer service, auditing, marketing, debugging to
              identify and repair errors that impair existing intended functionality on our Websites,
              Applications, or Products, and/or protecting against malicious, deceptive, fraudulent, or
              illegal activity).
            </p>
            <p class="text-muted">
              To subsidiaries and affiliates. We may disclose or share your information with current or
              future Agile Onboarding subsidiaries and affiliates to further facilitate your use of our
              Applications, Websites, and Products, and to ensure the smooth and consistent operations of
              Agile Onboarding by identifying and repairing errors that impede intended functionality and
              to protect against malicious, deceptive, fraudulent, or illegal activity.
            </p>
            <p class="text-muted">
              For legal process and protection. We may disclose or share your information to satisfy any
              law, regulation, legal process, governmental request, or where we have a good faith belief
              that access, use, preservation or disclosure of such information is reasonably necessary
              to:Enforce or apply agreements, or initiate, render, bill, and collect for Products;
              Protect our rights or interests, property, or safety or that of others;In connection with
              claims, disputes, or litigation - in court or elsewhere; Protect users of our Applications,
              Websites, and Products and other carriers or providers from fraudulent, abusive, or
              unlawful use of, or subscription to, such services; and Facilitate or verify the
              appropriate calculation of taxes, fees, or other obligations due to a local, state, or
              federal government.
            </p>

            <h5 class="card-title">Links To Third Party Sites</h5>
            <p class="text-muted">
              Our Applications and Websites may contain links to third party websites and services.
              Please note that these links are provided for your convenience and information, and the
              websites and services may operate independently from us and have their own privacy policies
              or notices, which we strongly suggest you review. This Privacy Notice applies to our
              Applications and Websites only, and we do not accept any responsibility or liability for
              the policies or practices of any third parties.
            </p>
            <h5 class="card-title">Third Party Tracking / Do Not Track</h5>
            <p class="text-muted">
              Our Websites and Applications may, from time to time, collect information about your online
              activities, over time and across our different Websites and Applications. When you use our
              Websites and Applications, third parties may also collect information about your online
              activities, over time and across different internet websites, online or cloud computing
              services, online applications, or mobile applications. Some browsers support a "Do Not
              Track" feature, which is intended to be a signal to websites that you do not wish to be
              tracked across different websites you visit. Our Websites do not currently change the way
              they operate based upon detection of a "Do Not Track" or similar signal.
            </p>
            <h5 class="card-title">How Do We Store and Protect Your Information?</h5>

            <p class="text-muted">
              Although no system can guarantee the complete security of your information, we take all
              commercially reasonable steps to ensure your information is protected in alignment with all
              applicable laws and regulations, as appropriate to the sensitivity of your information.
            </p>
            <h5 class="card-title">How Long Do We Keep Your Personal Information?</h5>
            <p class="text-muted">
              We keep your information for as long as necessary in accordance with the purpose for which
              it was collected, our business needs, and our legal and regulatory obligations. If we
              dispose of your information, we will do so in a way that is secure and appropriate to
              nature of the information subject to disposal.
            </p>
            <h5 class="card-title">Cookies and Related Technology</h5>
            <p class="text-muted">
              We may use cookies (browser and flash), beacons, pixel tags, scripts, and other similar
              technologies from time to time to support the functionality of our Applications, Websites,
              and Products. A cookie is a piece of information contained in a very small text file that
              is stored in your Internet browser or elsewhere on your hard drive. Cookies are transferred
              from our Websites and Applications to your computer, phone, or tablet, and allow us to
              identify your device whenever you return to our Website or Application. These technologies
              provide a better experience when you use our Applications, Websites, and Products, and
              allow us to improve our services. We may also use analytic software, such as Google
              Analytics and others, to help better understand how our Applications and Websites function
              on your device(s) and for other analytical purposes. You can opt-out of Google Analytics by
              installing Google's opt-out browser add-on here: https://tools.google.com/dlpage/gaoptout.
            </p>
            <p class="text-muted">
              Below is an overview of the types of cookies and related technology we deploy, and your
              choices.
            </p>
            <p class="text-muted">
              <span class="font-weight-bold">Browser Cookies</span>. A browser cookie is a small file
              placed on the hard drive of your computer. That cookie then communicates with servers,
              ours, or those of other companies that we authorize to collect data for us and allows
              recognition of your personal computer. You may use the tools available on your computer or
              other device(s) to set your browser to refuse or disable all or some browser cookies, or to
              alert you when cookies are being set. However, if you refuse or disable all browser
              cookies, you may be unable to access certain parts or use certain features or functionality
              of our Applications and Websites. Unless you have adjusted your browser settings so that it
              refuses all cookies, we may use cookies when you direct your browser to our Applications
              and Websites.
            </p>
            <p class="text-muted">
              <span class="font-weight-bold">Flash Cookies</span>. Certain features of our Websites and
              Applications may use locally stored objects called flash cookies to collect and store
              information about your preferences and navigation to, from and on our Applications and
              Websites. We also may include cookies to provide aggregate reporting. Some of our Websites
              and Applications may allow Adobe to set and enable special cookies that are necessary to
              deliver video content for Adobe Flash Player. You cannot remove flash cookies simply by
              changing your browser settings. If you would like to limit the websites that can store
              information in flash cookies on your device, you must visit the Adobe website:
              http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html.
            </p>
            <p class="text-muted">
              <span class="font-weight-bold">Beacons</span>. Our Websites and e-mails may contain small
              electronic files known as beacons (also referred to as web beacons, clear GIFs, pixel tags
              and single-pixel GIFs) that permit us to, for example, to count users who have visited
              those pages or opened an e-mail and for other website-related statistics. Beacons in e-mail
              marketing campaigns allow us to track your responses and your interests in our content,
              offerings, and web pages. You may use the tools in your device to disable these
              technologies as well.
            </p>
            <p class="text-muted">
              <span class="font-weight-bold">Third Party Technology</span>. Our service providers,
              advertising partners, and ad networks may also use cookies and beacons to collect and share
              information about your activities both on our Websites and Applications and on other
              websites and applications. In addition, third parties that are unaffiliated with us may
              also collect information about you, including tracking your browsing history, when you use
              our Applications and Websites. We do not have control over these third-party collection
              practices. If you wish to minimize these third-party collections, you can adjust the
              settings of your browsers or install plug-ins and add-ins.
            </p>
            <p class="text-muted">
              <span class="font-weight-bold">Your Choices</span>. You may wish to restrict the use of
              cookies or completely prevent them from being set. Most browsers provide for ways to
              control cookie behavior, such as the length of time they are stored. If you disable
              cookies, please be aware that some of the features of our Websites may not function
              correctly. To find out more on how to manage and delete cookies, visit
              www.aboutcookies.org. For more details on your choices regarding use of your web browsing
              activity for interest-based advertising, you may visit the following sites:
            </p>
            <a href="http://networkadvertising.org/"
              ><p class="text-muted">http://networkadvertising.org/</p></a
            >
            <a href="http://optout.aboutads.info/"
              ><p class="text-muted">http://optout.aboutads.info/</p></a
            >
            <a href="http://youradchoices.com/"><p class="text-muted">http://youradchoices.com/</p></a>
            <h5 class="card-title">Your Choices</h5>
            <p class="text-muted">
              We provide you the ability to exercise certain controls and choices regarding our
              collection, use, storage, and sharing of your information. Please note that for some of
              these rights, such as the Right to Know and Right to Delete, we will verify your identity
              before we respond to your request. To verify your identity, we will generally match the
              identifying information provided in your request with the information we have on file about
              you. Depending on the sensitivity of the personal information requested to be deleted, we
              may also utilize more stringent verification methods to verify your identity, including but
              not limited to requesting additional information from you or requiring you to sign a
              declaration under penalty of perjury.
            </p>
            <p class="text-muted">
              <span class="font-weight-bold">Right of Correction</span>. Regardless of where you live,
              you may submit a request that we correct or update the information we have about you
            </p>
            <p class="text-muted">
              <span class="font-weight-bold">Right to Know</span>. If you are a California resident, you
              have a right to ask that we disclose to you the categories and specific pieces of personal
              information we have collected about you in the 12 months preceding the date of your
              request, including a description of the categories of sources from which we have collected
              that information, the business or commercial purpose for collecting or sharing that
              information, and the categories of third parties with whom we have shared that information.
              To submit such a request, you may email
              <span class="ao-primary font-italic" style="text-decoration: underline"
                ><a href="info@agileonboarding.com"
                  ><p class="text-muted">info@agileonboarding.com</p></a
                ></span
              >
            </p>
            <p class="text-muted">
              <span class="font-weight-bold">Right to Change Preferences</span>. Regardless of where you
              live, you may change your choices for subscriptions, newsletters, and alerts.
            </p>
            <p class="text-muted">
              <span class="font-weight-bold">Right to Control Advertising and Online Tracking</span>.
              Regardless of where you live, you have a right to control how your information is tracked
              online. To learn more about these rights, see Cookies and Related Technologies.
            </p>
            <p class="text-muted">
              <span class="font-weight-bold">Right of Non-Discrimination</span>. You have a right to
              exercise your rights under this section free of discrimination. Agile Onboarding will not
              discriminate against you in any way if you choose to exercise your rights under this
              section.
            </p>
            <h5 class="card-title">International Jurisdictions</h5>
            <p class="text-muted">
              Our Applications, Websites, and Products are hosted and offered in the United States of
              America (US), and are subject to US federal, state, and local law. If you are accessing the
              Applications, Websites, or Products from another country, please be advised that you may be
              transferring your information to us in the US, and you consent to that transfer and use of
              your information in accordance with this Policy. You also agree to abide by the applicable
              laws of applicable US federal, state, and local laws concerning your use of the
              Applications, Websites, and Products, and your agreements with us. Any persons accessing
              our Applications, Websites, or Products from any jurisdiction with laws or regulations
              governing the use of the Internet, including the collection, use, or disclosure of
              information, different from those of the jurisdictions mentioned above may only use the
              Applications, Websites, or Products in a manner lawful in their jurisdiction. If your use
              of the Applications, Websites, or Products would be unlawful in your jurisdiction, you may
              not use the Applications, Websites, or Products.
            </p>
            <h5 class="card-title">Changes To Our Privacy Policy</h5>
            <p class="text-muted">
              We may change this Policy from time to time. Any and all changes will be reflected on this
              page, and where appropriate provided in person or by another electronic method. The
              effective date will be stated at the top of this Policy. You should regularly check this
              page for any changes to this Privacy Notice.
            </p>
            <p class="text-muted">
              YOUR CONTINUED USE, ACCESS, OR INTERACTION WITH THE APPLICATIONS, WEBSITES, OR PRODUCTS, OR
              YOUR CONTINUED COMMUNICATIONS WITH US AFTER THE UPDATED POLICY HAS BEEN POSTED WILL
              REPRESENT THAT YOU HAVE READ AND UNDERSTOOD THIS POLICY.
            </p>
            <h5 class="card-title">Contact Us</h5>
            <span class="ao-primary font-italic" style="text-decoration: underline"
              ><a href="info@agileonboarding.com"
                ><p class="text-muted">info@agileonboarding.com</p></a
              ></span
            >

            <a href="javascript:window.print()" class="btn btn-soft-primary d-print-none">Print</a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Privacy -->

<!-- Back to top -->
<a class="btn btn-icon btn-primary back-to-top" id="back-to-top" (click)="scrollTo('home')">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
