<owl-carousel-o id="customer-testi" [options]="customOptions">
  <ng-container *ngFor="let reviewer of testimonialData">
    <ng-template carouselSlide>
      <div class="tiny-slide">
        <div class="d-flex client-testi m-2">
          <div class="flex-1 p-3 shadow rounded bg-white position-relative">
            <ul class="list-unstyled mb-0">
              <li class="list-inline-item">
                <i class="mdi mdi-star text-warning"></i>
              </li>
              <li class="list-inline-item">
                <i class="mdi mdi-star text-warning"></i>
              </li>
              <li class="list-inline-item">
                <i class="mdi mdi-star text-warning"></i>
              </li>
              <li class="list-inline-item">
                <i class="mdi mdi-star text-warning"></i>
              </li>
              <li class="list-inline-item">
                <i class="mdi mdi-star text-warning"></i>
              </li>
            </ul>
            <p class="text-muted mt-2">{{ reviewer?.message }}</p>
            <p class="text-primary">
              - <strong>{{ reviewer?.name }}</strong> <br /><small
                class="text-muted"
                ><em>{{ reviewer?.designation }}</em></small
              >
              <br /><small class="text-muted">{{ reviewer?.company }}</small>
            </p>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
</owl-carousel-o>
