import { ViewportScroller } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-page-privacy',
  templateUrl: './page-privacy.component.html',
  styleUrls: ['./page-privacy.component.css'],
})
export class PagePrivacyComponent {
  constructor(private viewportScroller: ViewportScroller) {}

  scrollTo(elementId: string) {
    this.viewportScroller.scrollToAnchor(elementId);
  }
}
