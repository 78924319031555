<!-- Hero Start -->
<section
  class="bg-half-260 w-100 d-table"
  style="background: url('assets/images/event/event.jpg') center center"
  id="home"
>
  <div class="bg-overlay bg-primary bg-gradient" style="opacity: 0.85"></div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10 text-center">
        <div class="title-heading">
          <h4 class="text-white mb-3">July 26th & 27th 2022</h4>
          <h1 class="display-4 title-dark text-white fw-bold mb-3">
            Build an "Agile Recruiting" Team
          </h1>
          <h1 class="display-4 title-dark text-white fw-bold mb-3">
            2-Day Workshop
          </h1>
          <p class="para-desc title-dark mx-auto text-light">
            Transform your company's recruiting performance by learning the
            best, proven strategies and techniques. This two-day workshop will
            disclose never-before-released tools to maximize revenue by hiring
            more employees.
          </p>

          <div class="row">
            <div class="col-md-12 text-center">
              <div id="eventdown">
                <ul class="count-down list-unstyled">
                  <li id="days" class="count-number list-inline-item px-4">
                    {{ _days }}
                    <p class="count-head">Days</p>
                  </li>
                  <li id="hours" class="count-number list-inline-item px-4">
                    {{ _hours }}
                    <p class="count-head">Hours</p>
                  </li>
                  <li id="mins" class="count-number list-inline-item px-4">
                    {{ _minutes }}
                    <p class="count-head">Minutes</p>
                  </li>
                  <li id="secs" class="count-number list-inline-item px-4">
                    {{ _seconds }}
                    <p class="count-head">Seconds</p>
                  </li>
                  <li id="end" class="h1"></li>
                </ul>
              </div>
            </div>
          </div>

          <a class="btn btn-light mt-2 me-2" (click)="scrollTo('tickets')"
            >Register Now</a
          >
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-light">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
        fill="currentColor"
      ></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Speakers Start -->
<section class="section bg-light">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-6 text-center">
        <div class="section-title mb-4">
          <h1 class="display-4 title-dark mb-4">
            Why isn’t your company growing more quickly?
          </h1>
        </div>
      </div>
      <div class="col-6 text-center">
        <div class="section-title mb-4">
          <h1 class="display-4 title-dark mb-4">
            You have the open jobs,
            <strong>but you can’t find the people.</strong>
          </h1>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Speakers End -->

<!--  End -->
<section class="section border-top">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-5 pb-2">
          <h1 class="display-4 title-dark fw-bold mb-3">What You'll Learn</h1>
        </div>
      </div>

      <div class="row no-gutters align-items-start mb-5">
        <div class="col-lg-6 ross-image-container">
          <img
            src="assets/images/event/suit.jpg"
            class="img-fluid"
            style="max-height: 500px"
            alt=""
          />
        </div>
        <div class="col-lg-6">
          <h5 class="para-desc mx-auto">
            During this two-day training, we will teach your team:
          </h5>
          <ul>
            <li>
              How to structure an <strong>Agile Recruiting Process</strong>:
            </li>
            <ul>
              <li>Daily Huddles</li>
              <li>Streamlined Hirhing Process</li>
              <li>Working in Sprints</li>
              <li>Complete Visibility</li>
              <li>Key Performance Indicators</li>
            </ul>
            <li>
              How to <strong>Source</strong> candidates, and where to find them.
            </li>
            <li>
              How to Attract and Engage candidates, and exactly what to say!
            </li>
            <li>How to overcome the most common objections</li>
            <li>
              How to maximize each Candidate interaction to help you hire more
              people each year
            </li>
            <li>Take away materials to keep your company on track</li>
            <li>And much more!</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center align-items-center w-100">
      <a
        (click)="scrollTo('tickets')"
        class="btn btn-md btn-outline-primary mouse-down w-25"
      >
        Register Now
      </a>
    </div>
  </div>
</section>

<!-- About START -->
<section class="section border-top">
  <div class="container">
    <div class="card rounded shadow border-0 bg-light overflow-hidden">
      <div class="row no-gutters align-items-center">
        <div class="col-lg-6 ross-image-container">
          <img src="assets/images/ross.png" class="img-fluid" alt="" />
        </div>
        <!--end col-->
        <div class="col-lg-6">
          <div class="card-body section-title p-md-5">
            <h1 class="mb-1">About our Host</h1>
            <div class="d-inline-flex">
              <h4 class="mb-0">Ross O'Rourke &nbsp;</h4>
              <ul class="list-unstyled social-icon team-icon mb-0">
                <li class="list-inline-item me-1">
                  <a
                    href="https://www.linkedin.com/in/ross-o-rourke-5a2ba210/"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="rounded"
                  >
                    <i-feather
                      name="linkedin"
                      class="fea icon-sm fea-social"
                    ></i-feather>
                  </a>
                </li>
              </ul>
            </div>
            <p class="mb-0 text-muted">Co-Founder & CEO, AgileATS</p>
            <p class="mb-4 text-muted">
              Co-Founder & former CEO, iC-1 Solutions
            </p>
            <h4 class="mb-4">
              Ross O’Rourke grew his company, iC-1 Solutions, from
              <strong class="text-primary">zero</strong> to nearly 250 people in
              just <strong class="text-primary">three</strong> years,
              organically.
            </h4>
            <h4 class="mb-0">
              All employees were TS/SCI w/ poly, and highly technical (Engineers
              or Developers).
            </h4>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end card-->
  </div>
  <!--end container-->
</section>
<!--end section-->

<!-- About End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
        fill="currentColor"
      ></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Schedule Start -->
<section class="section bg-light">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h1 class="display-4 title-dark fw-bold mb-3">Event Schedule</h1>
          <p class="text-muted para-desc mx-auto mb-5">
            Our two-day recruiting workshop will be jam-packed with
            transformative training and networking sessions. Register as soon as
            possible while seats are available!
          </p>

          <h5 class="para-desc mx-auto mb-0">Pike & Gallows Business Center</h5>
          <p class="para-desc mx-auto mb-0">
            Tysons Corner 1934 Old Gallows Rd Suite 350, Vienna, VA 22182
          </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row mt-4 pt-2 justify-content-center">
      <agile-schedule-list></agile-schedule-list>
    </div>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Schedule End -->

<!-- CTA Start -->
<!-- CTA End -->

<!-- Price Start -->
<section class="section" id="tickets">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h1 class="display-4 title-dark fw-bold mb-3">Admission Fees</h1>
          <p class="text-muted para-desc mx-auto mb-0">
            Our world-class training workshop comes at an affordable,
            competitive price that you can't find anywhwere else in the market!
          </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row align-items-center justify-content-center">
      <div class="col-md-4 col-12 mt-4 pt-2">
        <div
          class="card pricing-rates best-plan text-center border-0 shadow-lg rounded"
        >
          <div class="ribbon ribbon-right ribbon-secondary overflow-hidden">
            <span class="text-center d-block shadow small">Available</span>
          </div>
          <div class="card-body py-5">
            <img
              src="assets/images/ticket.svg"
              class="mb-4"
              height="50"
              alt=""
            />
            <h6 class="title text-uppercase fw-bold mb-4">One Attendee</h6>
            <div class="d-flex justify-content-center mb-4">
              <span class="h4 mb-0 mt-2">$</span>
              <span class="price h1 mb-0">1,199</span>
            </div>

            <ul class="list-unstyled text-muted align-items-start">
              <li class="mb-4">
                <span class="text-primary h5 me-2"
                  ><i class="uil uil-check-circle align-middle"></i
                ></span>
                Hands-on training on how organize your organization to maximize
                hiring
              </li>
              <li class="mb-4">
                <span class="text-primary h5 me-2"
                  ><i class="uil uil-check-circle align-middle"></i
                ></span>
                Tactics, Best Practices, Group Workshop Scenarios and take-away
                material
              </li>
              <li class="mb-0">
                <span class="text-primary h5 me-2"
                  ><i class="uil uil-check-circle align-middle"></i
                ></span>
                Coffee and snacks all day!
              </li>
            </ul>

            <a
              (click)="scrollTo('tickets')"
              class="btn btn-md btn-outline-primary mouse-down"
              href="https://buy.stripe.com/28o9Br0w44jhenmbIK"
              target="_blank"
            >
              Register Now
            </a>
          </div>
        </div>
      </div>

      <div class="col-md-4 col-12 mt-4 pt-2">
        <div
          class="card pricing-rates best-plan text-center border-0 shadow-lg rounded"
        >
          <div class="ribbon ribbon-right ribbon-secondary overflow-hidden">
            <span class="text-center d-block shadow small">Available</span>
          </div>
          <div class="card-body py-5">
            <img
              src="assets/images/ticket.svg"
              class="mb-4"
              height="50"
              alt=""
            />
            <h6 class="title text-uppercase fw-bold mb-4">Two Attendees</h6>
            <div class="d-flex justify-content-center mb-4">
              <span class="h4 mb-0 mt-2">$</span>
              <span class="price h1 mb-0">1,499</span>
            </div>

            <ul class="list-unstyled text-muted align-items-start">
              <li class="mb-4">
                <span class="text-primary h5 me-2"
                  ><i class="uil uil-check-circle align-middle"></i
                ></span>
                Hands-on training on how organize your organization to maximize
                hiring
              </li>
              <li class="mb-4">
                <span class="text-primary h5 me-2"
                  ><i class="uil uil-check-circle align-middle"></i></span
                >Tactics, Best Practices, Group Workshop Scenarios and take-away
                material
              </li>
              <li class="mb-0">
                <span class="text-primary h5 me-2"
                  ><i class="uil uil-check-circle align-middle"></i></span
                >Coffee and snacks all day!
              </li>
            </ul>

            <a
              (click)="scrollTo('tickets')"
              class="btn btn-md btn-outline-primary mouse-down"
              href="https://buy.stripe.com/14k14V5Qo3fd7YYaEH"
              target="_blank"
            >
              Register Now
            </a>
          </div>
        </div>
      </div>
    </div>
    <!--end row-->
  </div>
</section>
<!--end section-->
<!-- Price End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-light">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
        fill="currentColor"
      ></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- News Start -->
<section class="section bg-light">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h1 class="display-4 title-dark fw-bold mb-3 text-primary">
            Register Today!
          </h1>
          <p class="text-muted para-desc mx-auto mb-0">
            Don't miss out on one of the best opportunities to learn how to
            maximize your company's revenues by implementing time-tested and
            proven strategies that capture and onboard the best candidates into
            your company!
          </p>
          <button
            class="btn btn-primary mt-4"
            (click)="scrollTo('tickets')"
            id="newssubscribebtn"
          >
            Register
          </button>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End News -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-footer">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
        fill="currentColor"
      ></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Back to top -->
<a
  class="btn btn-icon btn-primary back-to-top"
  id="back-to-top"
  (click)="scrollTo('home')"
>
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
